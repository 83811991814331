import axios from "axios";
import { BASE_URL } from "../../config/constants";

export default class Api {
  /**** PUBLIC */

  static async setRanking(email, ranking) {}

  /**** AUTHENTICATION  */

  static async authenticate(email, password) {
    try {
      const { data, status } = await axios.post(`${BASE_URL}/auth/login`, {
        username: email,
        password: password,
      });
      return { ...data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getAuthenticatedUser() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(`${BASE_URL}/profile`, header);
      return { ...data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getUserFromActivationKey(key) {
    try {
      const { data, status } = await axios.get(`${BASE_URL}/activation/${key}`);
      return { user: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async resetPassword(key, password, passwordConfirmation) {
    try {
      const { data, status } = await axios.put(
        `${BASE_URL}/users/password-reset/${key}`,
        {
          newPassword: password,
          repeatNewPassword: passwordConfirmation,
        }
      );
      return { user: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async requestActivationKey(email) {
    try {
      const { status } = await axios.put(`${BASE_URL}/users/password/request`, {
        email: email,
      });
      return { success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async changePassword(oldPassword, password, passwordConfirmation) {
    try {
      const header = Api.getHeader();
      const { status } = await axios.put(
        `${BASE_URL}/users/password/change`,
        {
          password: oldPassword,
          newPassword: password,
          repeatNewPassword: passwordConfirmation,
        },
        header
      );
      return { success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static getHeader() {
    const token = localStorage.getItem("token");
    return {
      headers: { Authorization: `Bearer ${token}` },
    };
  }

  /******* USERS */

  static async getUsers() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(`${BASE_URL}/users`, header);
      return { usersList: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getUser(id) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/users/${id}`,
        header
      );
      return { user: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getProfiles() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/users/profiles`,
        header
      );
      return { profiles: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getCountries() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/users/countries`,
        header
      );
      return { countries: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getLanguages() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/users/languages`,
        header
      );
      return { languages: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async registerUser(user) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/users`,
        {
          email: user.email,
          password: user.password,
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          extension: user.extension,
          languageCode: user.language,
          countryId: user.country,
          profileId: user.profile,
        },
        header
      );
      return { user: data, success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  static async updateUser(id, user) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.put(
        `${BASE_URL}/users/${id}`,
        {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          extension: user.extension,
          languageCode: user.language,
          countryId: user.country,
          profileId: user.profile,
          status: user.status.toString() === "true",
        },
        header
      );
      console.log(data);
      return { user: data, success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  static async getProcessPermissions(userId) {}

  static async setProcessPermission(userId, processId, enabled) {}

  /** PROCESSES */

  static async getProcesses() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(`${BASE_URL}/processes`, header);
      return { processesList: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getRootProcesses() {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/processes/type/ROOT`,
        header
      );
      return { processesList: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getProcess(id) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/processes/${id}`,
        header
      );
      return { process: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getProcessLanguage(id, languageCode) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/processes/language/${id}/${languageCode}`,
        header
      );
      return { process: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async registerProcess(process) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes`,
        {
          parentId: process.parentId || null,
          imageId: process.imageId || null,
          processName: process.processName,
          processType: process.processType,
        },
        header
      );
      return { process: data, success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  static async updateProcess(id, process) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.put(
        `${BASE_URL}/processes/${id}`,
        {
          parentId: process.parentId || null,
          imageId: process.imageId || null,
          processName: process.processName || null,
          processType: process.processType || null,
          responsibleName: process.responsibleName || null,
          responsibleEmail: process.responsibleEmail || null,
          responsiblePhone: process.responsiblePhone || null,
          status: process.status || null,
        },
        header
      );
      return { process: data, success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  static async deleteProcess(id) {
    try {
      const header = Api.getHeader();
      const { status } = await axios.delete(
        `${BASE_URL}/processes/${id}`,
        header
      );
      return { success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  static async setProcessAlias(processId, languageCode, processAlias) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/alias/${processId}`,
        {
          languageCode: languageCode,
          processAlias: processAlias,
        },
        header
      );
      return { processAlias: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  /**** IMAGES */

  static async getImage(id) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.get(
        `${BASE_URL}/images/${id}`,
        header
      );
      return { image: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async registerImage(file) {
    try {
      const formData = new FormData();
      formData.append("image", file);

      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/images`,
        formData,
        header
      );
      return { image: data, success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  /****** CONTACTS */

  static async registerContact(processId, contact) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/contact/${processId}`,
        {
          contactName: contact.contactName,
          contactEmail: contact.contactEmail,
          contactPhone: contact.contactPhone,
        },
        header
      );
      return { contact: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async deleteContact(id) {
    try {
      const header = Api.getHeader();
      const { status } = await axios.delete(
        `${BASE_URL}/processes/contact/${id}`,
        header
      );
      return { success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  /******* LINKS */

  static async registerLink(processId, link) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/link/${processId}`,
        {
          linkName: link.linkName,
          url: link.url,
        },
        header
      );
      return { link: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async deleteLink(id) {
    try {
      const header = Api.getHeader();
      const { status } = await axios.delete(
        `${BASE_URL}/processes/link/${id}`,
        header
      );
      return { success: true, status: status };
    } catch (e) {
      console.log(e);
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  /******* DESCRIPTION */

  static async setDescription(processId, languageCode, description) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/description/${processId}/${languageCode}`,
        {
          description: description,
        },
        header
      );
      return { description: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  /*********** VIDEO */

  static async setVideo(processId, languageCode, file) {
    try {
      const formData = new FormData();
      formData.append("video", file);

      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/videos/${processId}/${languageCode}`,
        formData,
        header
      );
      return { video: data, success: true, status: status };
    } catch (e) {
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  /******** DOCUMENTS */

  static async setDocument(processId, languageCode, file) {
    try {
      const formData = new FormData();
      formData.append("document", file);

      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/documents/${processId}/${languageCode}`,
        formData,
        header
      );
      return { document: data, success: true, status: status };
    } catch (e) {
      const { data, statusCode } = Api.getErrorData(e);
      return {
        success: false,
        error: data.message,
        status: statusCode,
      };
    }
  }

  /**** VISITS AND RAITINGS */
  static async setVisit(rootId, processId) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/visit/${rootId}/${processId}`,
        {},
        header
      );
      return { visit: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async setRating(rootId, processId, stars, email, comments) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/rating/${rootId}/${processId}`,
        {
          stars: stars,
          email: email,
          comments: comments,
        },
        header
      );
      return { rating: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static async getReport(initialDate, finalDate) {
    try {
      const header = Api.getHeader();
      const { data, status } = await axios.post(
        `${BASE_URL}/processes/report/byDay`,
        {
          initialDate: initialDate,
          finalDate: finalDate,
        },
        header
      );
      return { report: data, success: true, status: status };
    } catch (e) {
      return {
        success: false,
        error: e.response.data.message,
        status: e.response.statusCode,
      };
    }
  }

  static getErrorData(error) {
    return (
      error.response || {
        data: { message: error },
        statusCode: -1,
      }
    );
  }
}
