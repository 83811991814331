import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import PublicHeader from "./Components/PublicHeader";
import PublicFooter from "./Components/PublicFooter";
import RootView from "./Components/RootView";
import IntermediateView from "./Components/IntermediateView";
import FinalView from "./Components/FinalView";
import PageLoader from "../../components/Common/PageLoader";

import {
  getProcessWithLanguage,
  getRootPublishedProcesses,
} from "../../actions/processesActions";
import logoE2E from "../../images/logo_e2e.png";
import i18n from "../../i18n";

const Start = (props) => {
  const { auth, languages, processes } = useSelector((state) => {
    return state;
  });

  const dispatch = useDispatch();

  const rootId = props.match.params.rootId || 0;
  const processId = props.match.params.processId || rootId;

  useEffect(() => {
    if (processId !== 0)
      dispatch(getProcessWithLanguage(processId, languages.selectedLanguage));
    else dispatch(getRootPublishedProcesses());
  }, [dispatch, processId, languages.selectedLanguage]);

  const bodyStyle = {
    marginLeft: 0,
  };

  return (
    <div className="layout-container">
      <PublicHeader />
      <main className="main-container" style={bodyStyle}>
        <div className="container">
          <div className="row">
            <div className="col-12 text-right">
              <Link className="btn btn-link red-henkel" to="/navigation">
                {i18n.t("start.home")}
              </Link>
              {auth.isAuthenticated ? (
                <Link className="btn btn-link red-henkel" to="/home">
                  Dashboard
                </Link>
              ) : (
                <Link className="btn btn-link red-henkel" to="/login">
                  {i18n.t("start.adminLogin")}
                </Link>
              )}
              {/* <Link className="btn btn-link red-henkel" to="/what-is">
                {i18n.t("start.whatIsE2E")}
              </Link> */}
              <Link className="btn btn-link red-henkel" to="/support-e2e">
                {i18n.t("start.support")}
              </Link>
            </div>
            <div className="col-md-2 offset-md-0 col-sm-4 offset-sm-4 col-6 offset-3 mt-3">
              <Link to="/navigation">
                <img src={logoE2E} alt="E2E" className="img-fluid" />
              </Link>
            </div>
          </div>
          <h5>
            <strong>{i18n.t("start.iWantInfo")}</strong>
          </h5>
          {rootId === 0 ? (
            <RootView processes={processes.processesList} />
          ) : processes.process.processType !== "FINAL" ? (
            <IntermediateView
              rootId={rootId}
              process={processes.process}
              children={processes.children}
              parent={processes.parent}
              language={languages.selectedLanguage}
            />
          ) : processes.process.processType === "FINAL" ? (
            <FinalView
              rootId={rootId}
              process={processes.process}
              children={processes.children}
              parent={processes.parent}
              language={languages.selectedLanguage}
            />
          ) : (
            <PageLoader />
          )}
        </div>
        <PublicFooter />
      </main>
    </div>
  );
};

export default withTranslation("translations")(Start);
