import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import logo from "../../../images/logo_henkel.png";
import { setLanguage } from "../../../actions/languagesActions";
import i18n from "../../../i18n";

const PublicHeader = (props) => {
  const dispatch = useDispatch();

  const { languages } = useSelector((state) => {
    return state;
  });

  const [state, setState] = useState({
    pageTitle: "",
    navIcon: "menu-link-slide",
    dropdownOpen: false,
  });

  const selectedLanguage = languages.languages.find((language) => {
    return language.code === languages.selectedLanguage;
  });

  const headerStyle = {
    marginLeft: 0,
  };

  const toggle = () => {
    setState((prevState) => ({
      ...state,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const changeLanguage = (languageCode) => {
    i18n.changeLanguage(languageCode);
    dispatch(setLanguage(languageCode));
  };

  return (
    <header className="header-container" style={headerStyle}>
      <nav>
        <h2 className="header-title">
          <Link to="/navigation">
            <img src={logo} alt="Henkel" style={{ height: 60 }} />
          </Link>
        </h2>

        <ul className="float-right">
          <Dropdown
            id="basic-nav-dropdown"
            tag="li"
            isOpen={state.dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle nav className="has-badge ripple">
              {selectedLanguage ? selectedLanguage.languageName : ""}{" "}
              <FontAwesomeIcon icon={faChevronDown} />
            </DropdownToggle>
            <DropdownMenu right className="md-dropdown-menu">
              {languages.languages.map((language, index) => {
                return language.code !== selectedLanguage.code ? (
                  <div
                    onClick={() => changeLanguage(language.code)}
                    key={index}
                  >
                    <DropdownItem>{language.languageName}</DropdownItem>
                  </div>
                ) : (
                  <div key={index}></div>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </ul>
      </nav>
    </header>
  );
};

export default PublicHeader;
