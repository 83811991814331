import React, { useState, useEffect, useCallback } from "react"
import { TabContent, TabPane, Nav, NavItem, NavLink, Col } from "reactstrap"
import StarRatings from "react-star-ratings"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import validator from "validator"
import {
  faArrowLeft,
  faArrowRight,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons"
import ReactPlayer from "react-player"
import { Document, Page, pdfjs } from "react-pdf"
import { useDispatch } from "react-redux"
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core"

import { BASE_URL } from "../../../config/constants"
import PageLoader from "../../../components/Common/PageLoader"
import ContactBox from "../../../components/ContactBox/ContactBox"
import ResponsibleBox from "../../../components/ResponsibleBox/ResponsibleBox"
import LinkBox from "../../../components/LinkBox/LinkBox"
import ProcessBox from "../../Processes/Components/ProcessBox"
import i18n from "../../../i18n"
import { setVisit, updateRating } from "../../../actions/processesActions"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const FinalView = (props) => {
  const rootId = props.rootId
  const parent = props.parent
  const current = props.process
  const language = props.language
  const root = {
    id: rootId,
    processName: i18n.t("start.start"),
    processType: "MEDIUM",
  }

  let currentVideUrl = current.video
    ? current.video.length > 0 && current.video[0] != null
      ? `${BASE_URL}/videos/watch/${current.video[0].uri}`
      : ""
    : ""

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState("1")
  const [videoUrl, setVideoUrl] = useState(currentVideUrl === "" ? null : currentVideUrl)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [registerVisit, setRegisterVisit] = useState(false)
  const [rating, setRating] = useState(3)
  const [dialog, setDialog] = useState({
    open: false,
    submitted: false,
    ratingEmail: "",
    emailIsValid: false,
    ratingComments: "",
    rating: 3,
  })

  const calculateRating = useCallback(
    (initial, added) => {
      const totalUsers = current.rating.length + added
      const totalRating = current.rating.reduce(
        (acumulator, currentValue) => acumulator + currentValue.stars,
        initial
      )
      const averageRating = totalUsers === 0 ? 0 : totalRating / totalUsers
      setRating(averageRating)
    },
    [current]
  )

  useEffect(() => {
    if (!registerVisit) {
      dispatch(setVisit(rootId, current.id))
      setRegisterVisit(true)
    }
    calculateRating(0, 0)
  }, [rootId, current, dispatch, registerVisit, calculateRating])

  const classnames = (cond) =>
    Object.keys(cond)
      .map((k) => cond[k] && k)
      .join(" ")

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages)
  }

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const prevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1)
    }
  }

  const changeRating = (newRating, name) => {
    setDialog({
      ...dialog,
      open: true,
      rating: newRating,
    })
  }

  const changeDialogRating = (newRating) => {
    setDialog({
      ...dialog,
      rating: newRating,
    })
  }

  const changeActiveTab = (activeTab) => {
    if (activeTab !== "1") {
      setVideoUrl(null)
    } else {
      setVideoUrl(currentVideUrl)
    }
    setActiveTab(activeTab)
  }

  const setValue = (event) => {
    const input = event.target
    const value = input.type === "checkbox" ? input.checked : input.value

    setDialog({
      ...dialog,
      [input.name]: value,
    })
  }

  const handleDialogClose = () => {
    setDialog({
      ...dialog,
      open: false,
      submitted: false,
      emailIsValid: false,
      ratingEmail: "",
      ratingComments: "",
      rating: 3,
    })
  }

  const saveRating = () => {
    setDialog({
      ...dialog,
      submitted: true,
      emailIsValid: validator.isEmail(dialog.ratingEmail),
    })

    if (validator.isEmail(dialog.ratingEmail)) {
      dispatch(
        updateRating(
          rootId,
          current.id,
          dialog.rating,
          dialog.ratingEmail,
          dialog.ratingComments
        )
      )
      calculateRating(dialog.rating, 1)
      handleDialogClose()
    }
  }

  return (
    <div>
      <div className="row">
        <div className="col-3">
          <ProcessBox
            root={rootId}
            process={root}
            link={`/navigation/${rootId}/${rootId}`}
            languageCode={language}
          ></ProcessBox>
        </div>
        <div className="col-3">
          {parent && parent.id && (
            <ProcessBox
              root={rootId}
              process={parent}
              link={`/navigation/${rootId}/${parent.id}`}
              languageCode={language}
            ></ProcessBox>
          )}
        </div>
        <div className="col-3">
          {current.id && (
            <ProcessBox
              root={rootId}
              process={current}
              link={`/navigation/${rootId}/${current.id}`}
              languageCode={language}
            ></ProcessBox>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-9 cardbox">
          <Nav tabs className="nav-justified">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  changeActiveTab("1")
                }}
              >
                {i18n.t("start.video")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  changeActiveTab("2")
                }}
              >
                {i18n.t("start.stepByStep")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  changeActiveTab("3")
                }}
              >
                {i18n.t("start.document")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1" className="text-center">
              <h4 className="mb-4">{i18n.t("start.video")}</h4>
              {current.video ? (
                currentVideUrl !== "" ? (
                  <div className="div-video">
                    <ReactPlayer
                      url={videoUrl}
                      controls={true}
                      stopOnUnmount={true}
                    />
                  </div>
                ) : (
                  <div className="text-center">{i18n.t("start.noVideo")}</div>
                )
              ) : (
                <PageLoader />
              )}
            </TabPane>
            <TabPane tabId="2">
              <h4 className="mb-4 text-center">{i18n.t("start.stepByStep")}</h4>
              <div
                className="process-description"
                dangerouslySetInnerHTML={{
                  __html:
                    current.description.length > 0
                      ? current.description[0] == null
                        ? ""
                        : current.description[0].description
                      : "",
                }}
              />
            </TabPane>
            <TabPane tabId="3" className="text-center">
              <h4 className="mb-4">{i18n.t("start.document")}</h4>
              {current.document ? (
                current.document.length > 0 && current.document[0] != null ? (
                  <div>
                    <div className=" text-center mt-4 mb-4">
                      <Document
                        file={`${BASE_URL}/documents/watch/${current.document[0].uri}`}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={<PageLoader />}
                        className="pdf-document"
                      >
                        <Page pageNumber={pageNumber} />
                      </Document>
                    </div>
                    <div className="mt-2 row">
                      <div className="col-8 text-left">
                        <button
                          onClick={prevPage}
                          className="btn btn-henkel-secondary"
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                        </button>{" "}
                        {i18n.t("start.page")} {pageNumber} of {numPages}{" "}
                        <button
                          onClick={nextPage}
                          className="btn btn-henkel-secondary"
                        >
                          <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                      </div>
                      <div className="col-4 text-right">
                        <a
                          className="btn btn-henkel-secondary"
                          rel="noopener noreferrer"
                          href={`${BASE_URL}/documents/watch/${current.document[0].uri}`}
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faFileDownload} />{" "}
                          {i18n.t("start.download")}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    {i18n.t("start.noDocument")}
                  </div>
                )
              ) : (
                <PageLoader />
              )}
            </TabPane>
          </TabContent>
        </div>
        <div className="col-md-3">
          <ResponsibleBox
            responsible={current}
            title={i18n.t("start.responsible")}
          />
          {current.contact.length > 0 && (
            <ContactBox
              contact={current.contact}
              title={i18n.t("start.contacts")}
            />
          )}
          {current.link.length > 0 && (
            <LinkBox
              links={current.link}
              title={i18n.t("start.linksOfInterest")}
            />
          )}

          <div className="cardbox">
            <div className="cardbox-heading bg-danger">
              <span className="cardbox-title">{i18n.t("start.rating")}</span>
            </div>
            <div className="cardbox-body">
              <StarRatings
                rating={rating}
                starRatedColor="#FF9999"
                starHoverColor="#FF6666"
                starEmptyColor="#EEEEEE"
                starDimension="30px"
                changeRating={changeRating}
                numberOfStars={5}
                name="rating"
              />
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={dialog.open}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {i18n.t("start.addReview")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{dialog.body}</DialogContentText>
          <div className="text-center mb-4">
            <StarRatings
              rating={dialog.rating}
              starRatedColor="#FF9999"
              starHoverColor="#FF6666"
              starEmptyColor="#EEEEEE"
              starDimension="30px"
              changeRating={changeDialogRating}
              numberOfStars={5}
              name="rating"
            />
          </div>
          <div className="form-group row align-items-center">
            <label className="col-sm-4 col-form-label">
              {i18n.t("start.yourEmail")}:
              <br />
              <small className="text-danger">{i18n.t("mandatory")}</small>
            </label>
            <Col sm={8}>
              <input
                type="email"
                name="ratingEmail"
                className={`form-control ${
                  !dialog.emailIsValid && dialog.submitted ? "is-invalid" : ""
                }`}
                onChange={setValue}
                value={dialog.ratingEmail}
                placeholder={i18n.t("start.yourEmail")}
                maxLength={100}
                data-validate='["required", "email"]'
              />
              {dialog.submitted && !dialog.emailIsValid && (
                <span className="invalid-feedback">{i18n.t("fieldEmail")}</span>
              )}
            </Col>
          </div>
          <div className="form-group row align-items-center">
            <label className="col-sm-4 col-form-label">
              {i18n.t("start.yourComments")}:
              <br />
              <small className="text-success">{i18n.t("optional")}</small>
            </label>
            <Col sm={8}>
              <textarea
                className="form-control"
                onChange={setValue}
                name="ratingComments"
                placeholder={i18n.t("start.yourComments")}
                value={dialog.ratingComments}
              >
                {dialog.ratingComments}
              </textarea>
            </Col>
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleDialogClose}
            className="btn btn-henkel-secondary"
          >
            {i18n.t("cancel")}
          </button>
          <button onClick={saveRating} className="btn btn-henkel-primary">
            {i18n.t("save")}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default FinalView
