import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const ContactBox = (props) => {
  const contacts = props.contact;
  const title = props.title ?? "Contacts";

  return (
    <div className="cardbox">
      <div className="cardbox-heading bg-danger">
        <span className="cardbox-title">{title}</span>
      </div>
      <div className="cardbox-body">
        <div className="cardbox-title">
          {contacts.map((contact) => (
            <div key={contact.id} className="row">
              <div className="col-2 pt-2">
                <FontAwesomeIcon icon={faUserCircle} size="2x" />
              </div>
              <div className="col-10">
                <h5 className="mv-sm box-title">{contact.contactName}</h5>
                <p className="m0 box-info">
                  <a href={`mailto:${contact.contactEmail}`}>
                    {contact.contactEmail}
                  </a>
                </p>
                {contact.contactPhone !== "" && (
                  <p className="mt-1 box-info">
                    <a href={`tel:${contact.contactPhone}`}>
                      {contact.contactPhone}
                    </a>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ContactBox;
