import { GET_ALL_LANGUAGES, SET_LANGUAGE, LANGUAGES_LOADED } from "../types";

const initialState = {
  loading: false,
  getDone: false,
  error: "",
  languages: [],
  selectedLanguage: "es",
};

export function LanguagesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_LANGUAGES:
      return {
        ...state,
        languages: action.payload,
        getDone: true,
      };
    case LANGUAGES_LOADED:
      return {
        ...state,
        getDone: false,
      };
    case SET_LANGUAGE:
      console.log(action);
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    default:
      return state;
  }
}
