import React from "react";

const PublicFooter = () => (
  <footer className="text-center">
    <span className="pr-4">&copy; 2020 Henkel</span>
    <span className="pr-4 pl-4">Terms of use</span>
    <span className="pr-4 pl-4">Data protection</span>
    <span className="pl-4">@EnterpriseOpen!</span>
  </footer>
);

export default PublicFooter;
