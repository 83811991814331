import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import PropTypes from "prop-types";

import FormValidator from "../Forms/Validator.js";
import Footer from "../../components/Footer/Footer";
import "../Forms/Material.scss";
import LogoE2e from "../../images/logo_e2e.png";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../actions/authActions";
import HenkelLogo from "../../images/logo_henkel.png";

//class Login extends Component {
function Login() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => {
    return state.auth;
  });

  const [formLogin, setState] = useState({
    email: "",
    password: "",
    errors: {
      email: [],
      password: [],
    },
  });

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    setState({
      ...formLogin,
      [input.name]: value,
      errors: {
        ...formLogin.errors,
        [input.name]: result,
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setState({
      ...formLogin,
      errors,
    });

    if (!hasError) {
      dispatch(signIn(formLogin.email, formLogin.password));
    }
  };

  /* Simplify error check */
  const hasError = (formName, inputName, method) => {
    return (
      formLogin.errors &&
      formLogin.errors[inputName] &&
      formLogin.errors[inputName][method]
    );
  };

  return (
    <div className="layout-container bg-gray-henkel">
      <div className="page-container">
        <div className="container-full">
          <nav className="navbar bg-light fixed-top">
            <img src={HenkelLogo} alt="Henkel" className="logo-signin" />
          </nav>
          <div className="mt-4 mb-4 pt-4 pb-4">&nbsp;</div>
          <div className="container container-xs bg-common rounded-lg mt-4">
            <div className="row mb-4 pr-4 pl-4">
              <div className="text-center col-sm-6 offset-sm-3">
                <Link to="/">
                  <img className="mv-lg img-fluid" src={LogoE2e} alt="Henkel" />
                </Link>
              </div>
            </div>
            <div className=" pr-4 pl-4">
              <form
                className="cardbox b0 form-validate shadow"
                action=""
                name="formLogin"
                onSubmit={onSubmit}
              >
                <div className="cardbox-offset pb0">
                  <div className="cardbox-offset-item text-right invisible">
                    <div className="btn btn-success btn-circle btn-lg">
                      <em className="ion-checkmark-round" />
                    </div>
                  </div>
                </div>
                <div className="cardbox-heading">
                  <div className="cardbox-title text-process-explorer">
                    Sign In
                  </div>
                </div>
                <div className="cardbox-body">
                  {authState.error && (
                    <div className="alert alert-warning">{authState.error}</div>
                  )}
                  <div className="mda-form-group float-label mda-input-group">
                    <div className="mda-form-control">
                      <Input
                        type="email"
                        name="email"
                        invalid={
                          hasError("formLogin", "email", "required") ||
                          hasError("formLogin", "email", "email")
                        }
                        onChange={validateOnChange}
                        data-validate='["required"]'
                        value={formLogin.email}
                        placeholder="Company email"
                      />
                      <div className="mda-form-control-line" />
                    </div>
                    <span className="mda-input-group-addon">
                      <em className="ion-ios-person-outline icon-lg" />
                    </span>
                  </div>
                  <div className="mda-form-group float-label mda-input-group">
                    <div className="mda-form-control">
                      <Input
                        type="password"
                        name="password"
                        invalid={hasError("formLogin", "password", "required")}
                        onChange={validateOnChange}
                        data-validate='["required"]'
                        value={formLogin.password}
                        placeholder="Password"
                      />
                      <div className="mda-form-control-line" />
                    </div>
                    <span className="mda-input-group-addon">
                      <em className="ion-ios-locked-outline icon-lg" />
                    </span>
                  </div>
                  <button
                    className="btn btn-henkel-primary btn-block"
                    type="submit"
                    disabled={authState.loading}
                  >
                    {authState.loading ? "Authenticating..." : "Authenticate"}
                  </button>
                  <Link
                    className="btn btn-henkel-secondary btn-block mt-3"
                    to="/recover"
                  >
                    Forgot password?
                  </Link>
                  <Link
                    className="btn btn-link text-henkel btn-block mt-3"
                    to="/navigation"
                  >
                    Return to Process Explorer
                  </Link>
                </div>
              </form>
            </div>
          </div>
          <Footer fromLogin={true} />
        </div>
      </div>
    </div>
  );
}

Login.contextTypes = {
  router: PropTypes.object,
};

export default Login;
