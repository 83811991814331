import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";

import { putEmailForActivationKey } from "../../actions/usersActions";
import Footer from "../../components/Footer/Footer";
import FormValidator from "../Forms/Validator.js";
import "../Forms/Material.scss";
import LogoE2e from "../../images/logo_e2e.png";
import HenkelLogo from "../../images/logo_henkel.png";

function Recover() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);

  const [formRecover, setState] = useState({
    email: "",
    submitted: false,
    errors: {
      email: [],
    },
  });

  const [submitted, setSubmitted] = useState(false);

  /**
   * Validate input using onChange event
   * @param  {String} formName The name of the form in the state object
   * @return {Function} a function used for the event
   */
  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    const result = FormValidator.validate(input);

    setState({
      ...formRecover,
      [input.name]: value,
      errors: {
        ...formRecover.errors,
        [input.name]: result,
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);
    console.log(errors);
    console.log(hasError);

    setState({
      ...formRecover,
      errors: {
        ...formRecover.errors,
        errors,
      },
    });

    if (hasError) {
      return;
    }

    dispatch(putEmailForActivationKey(formRecover.email));
    setSubmitted(true);
  };

  /* Simplify error check */
  const hasError = (inputName, method) => {
    return (
      formRecover.errors &&
      formRecover.errors[inputName] &&
      formRecover.errors[inputName][method]
    );
  };

  return (
    <div className="layout-container bg-gray-henkel">
      <div className="page-container">
        <div className="container-full">
          <nav className="navbar bg-light fixed-top">
            <img src={HenkelLogo} alt="Henkel" className="logo-signin" />
          </nav>
          <div className="mt-4 mb-4 pt-4 pb-4">&nbsp;</div>
          <div className="container container-xs bg-common rounded-lg mt-4">
            <div className="row mb-4">
              <div className="text-center col-sm-6 offset-sm-3">
                <img className="mv-lg img-fluid" src={LogoE2e} alt="Henkel" />
              </div>
            </div>
            {submitted ? (
              <div
                className="cardbox bg-danger ng-fadeIn shadow"
                id="confirmation"
              >
                <div className="cardbox-heading">
                  <em className="ion-checkmark-round float-right icon-lg" />
                  <h4 className="mt0">Mail Sent!</h4>
                </div>
                <div className="cardbox-body">
                  <p>
                    An email was sent to <strong>{formRecover.email}</strong>.
                    Please check your inbox and follow instructions to reset
                    your password.
                  </p>
                </div>
                <div className="cardbox-footer text-right">
                  <Link
                    className="btn btn-danger btn-flat text-white"
                    to="/login"
                  >
                    Login
                    <em className="ion-ios-arrow-thin-right icon-fw" />
                  </Link>
                </div>
              </div>
            ) : (
              <div className=" pr-4 pl-4">
                <form
                  className="cardbox form-validate shadow"
                  action=""
                  name="formRecover"
                  onSubmit={onSubmit}
                >
                  <div className="cardbox-heading">
                    <p className="text-center text-inherit">
                      Fill with your username to receive instructions in your
                      mail on how to reset your password.
                    </p>
                  </div>
                  <div className="cardbox-body">
                    {authState.error && (
                      <div className="alert alert-warning">
                        {authState.error}
                      </div>
                    )}
                    <div className="mda-form-group float-label mda-input-group">
                      <div className="mda-form-control">
                        <Input
                          type="email"
                          name="email"
                          invalid={
                            hasError("email", "required") ||
                            hasError("email", "email")
                          }
                          onChange={validateOnChange}
                          data-validate='["required", "email"]'
                          value={formRecover.email}
                          placeholder="Company email"
                        />
                        <div className="mda-form-control-line" />
                      </div>
                      <span className="mda-input-group-addon">
                        <em className="ion-ios-person-outline icon-lg" />
                      </span>
                    </div>
                    <button
                      className="btn btn-henkel-primary btn-block"
                      type="submit"
                    >
                      Request instructions
                    </button>
                    <Link
                      className="btn btn-henkel-secondary btn-block mt-3"
                      to="/login"
                    >
                      Return to login
                    </Link>
                  </div>
                </form>
              </div>
            )}
          </div>
          <Footer fromLogin={true} />
        </div>
      </div>
    </div>
  );
}

export default Recover;
