import {
  LOADING,
  GET_REPORT,
  GET_PROCESS_LIST,
  GET_ROOT_PROCESSES,
  POST_PROCESS,
  PUT_PROCESS,
  GET_PROCESS,
  DELETE_PROCESS,
  SET_PROCESS_ALIAS,
  POST_CONTACT,
  DELETE_CONTACT,
  POST_LINK,
  DELETE_LINK,
  UPDATE_DESCRIPTION,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_RATING,
  POST_VISIT,
  ERROR,
  DATA_LOADED,
  CLEAN_PROCESS,
} from "../types";

const initialState = {
  search: "",
  loading: false,
  getReportDone: false,
  getRootDone: false,
  registerDone: false,
  updateDone: false,
  getDone: false,
  deleteDone: false,
  registerContactDone: false,
  deleteContactDone: false,
  registerLinkDone: false,
  deleteLinkDone: false,
  updateDescriptionDone: false,
  registerVideoDone: false,
  registerDocumentDone: false,
  registerVisitDone: false,
  registerRatingDone: false,
  error: "",
  processesList: [],
  process: {
    id: 0,
    processName: "",
  },
  parent: {},
  children: [],
  report: {
    visits: [],
    ratings: [],
  },
};

export function ProcessesReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        registerDone: false,
        getReportDone: false,
        getRootDone: false,
        updateDone: false,
        getDone: false,
        deleteDone: false,
        registerContactDone: false,
        deleteContactDone: false,
        registerLinkDone: false,
        deleteLinkDone: false,
        updateDescriptionDone: false,
        registerVideoDone: false,
        registerDocumentDone: false,
        registerVisitDone: false,
        registerRatingDone: false,
      };
    case GET_REPORT:
      return {
        ...state,
        loading: false,
        error: "",
        report: action.payload,
        getReportDone: true,
      };
    case GET_PROCESS_LIST:
      return {
        ...state,
        loading: false,
        error: "",
        processesList: action.payload,
      };
    case GET_ROOT_PROCESSES:
      return {
        ...state,
        loading: false,
        getRootDone: true,
        error: "",
        processesList: action.payload,
      };
    case GET_PROCESS:
      return {
        ...state,
        loading: false,
        getDone: true,
        error: "",
        process: action.payload,
        parent: action.payload.parent ?? {},
        children: action.payload.children,
      };
    case POST_PROCESS:
      return {
        ...state,
        loading: false,
        error: "",
        registerDone: true,
        process: action.payload,
      };
    case PUT_PROCESS:
      return {
        ...state,
        loading: false,
        error: "",
        updateDone: true,
        process: action.payload,
      };
    case DELETE_PROCESS:
      return {
        ...state,
        loading: false,
        error: "",
        deleteDone: true,
      };
    case SET_PROCESS_ALIAS: {
      return {
        ...state,
        loading: false,
        error: "",
      };
    }
    case POST_CONTACT: {
      return {
        ...state,
        loading: false,
        error: "",
        registerContactDone: true,
      };
    }
    case DELETE_CONTACT: {
      return {
        ...state,
        loading: false,
        error: "",
        deleteContactDone: true,
      };
    }
    case POST_LINK: {
      return {
        ...state,
        loading: false,
        error: "",
        registerLinkDone: true,
      };
    }
    case DELETE_LINK: {
      return {
        ...state,
        loading: false,
        error: "",
        deleteLinkDone: true,
      };
    }
    case UPDATE_DESCRIPTION: {
      return {
        ...state,
        loading: false,
        error: "",
        updateDescriptionDone: true,
      };
    }
    case POST_VIDEO: {
      return {
        ...state,
        loading: false,
        error: "",
        registerVideoDone: true,
      };
    }
    case POST_DOCUMENT: {
      return {
        ...state,
        loading: false,
        error: "",
        registerDocumentDone: true,
      };
    }
    case POST_RATING: {
      return {
        ...state,
        loading: false,
        error: "",
        registerRatingDone: true,
      };
    }
    case POST_VISIT: {
      return {
        ...state,
        loading: false,
        error: "",
        registerVisitDone: true,
      };
    }
    case DATA_LOADED:
      return {
        ...state,
        getRootDone: false,
        registerDone: false,
        updateDone: false,
        getDone: false,
        deleteDone: false,
        registerContactDone: false,
        deleteContactDone: false,
        registerLinkDone: false,
        deleteLinkDone: false,
        updateDescriptionDone: false,
        registerVideoDone: false,
        registerDocumentDone: false,
        registerVisitDone: false,
        registerRatingDone: false,
        getReportDone: false,
      };
    case CLEAN_PROCESS: {
      return {
        ...state,
        getDone: false,
        getReportDone: false,
      };
    }
    case ERROR:
      return {
        ...state,
        getRootDone: false,
        loading: false,
        registerDone: false,
        updateDone: false,
        getDone: false,
        deleteDone: false,
        registerContactDone: false,
        deleteContactDone: false,
        registerLinkDone: false,
        deleteLinkDone: false,
        updateDescriptionDone: false,
        registerVideoDone: false,
        registerDocumentDone: false,
        registerVisitDone: false,
        registerRatingDone: false,
        getReportDone: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
