import React from "react";
import { Link } from "react-router-dom";
import { Fab } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faProjectDiagram,
  faEdit,
  faLanguage,
  faFileInvoice,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

import { BASE_URL } from "../../../config/constants";
import "./ProcessBox.css";

const ProcessBox = (props) => {
  const root = props.root;
  const process = props.process;
  const link = props.link;
  const languageCode = props.languageCode || "en";
  const alias = process.processAlias
    ? process.processAlias.find(
        (processAlias) =>
          processAlias != null && processAlias.language.code === languageCode
      )
    : undefined;
  const processName = alias
    ? alias.processAlias !== ""
      ? alias.processAlias
      : process.processName
    : process.processName;

  const tableHeight = {
    height: "100%",
  };

  const content = {
    height: "100%",
    width: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundImage:
      process.processType === "ROOT"
        ? `url(${BASE_URL}/images/watch/${process?.image?.uri})`
        : "none",
  };
  return (
    <div className="text-center">
      <div className="processBox">
        <Link
          to={
            link !== undefined
              ? link
              : process.processType === "FINAL"
              ? `/processes/final/${root}/${process.id}`
              : `/processes/tree/${root}/${process.id}`
          }
        >
          <div
            className={`card card${
              process.processType === "FINAL"
                ? "Final"
                : process.processType !== "ROOT"
                ? "Medium"
                : "Root"
            }`}
          >
            <table style={tableHeight}>
              <tbody>
                <tr>
                  <td
                    className="align-middle text-center processTitle"
                    style={tableHeight}
                  >
                    {process.processType !== "ROOT" && processName}
                    {process.processType === "ROOT" && (
                      <div className="mh-100 mw-100" style={content}>
                        &nbsp;
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Link>
        <div className="row">
          <div className="col-3 processActions">
            {props.addLanguages && (
              <span onClick={props.addLanguages}>
                <Fab
                  color="default"
                  aria-label="languages"
                  title="Config languages"
                  className="ml-2"
                >
                  <FontAwesomeIcon icon={faLanguage} className="fa-2x" />
                </Fab>
              </span>
            )}
          </div>
          <div className="text-right col-9 processActions">
            {props.addProcess && (
              <span onClick={props.addProcess}>
                <Fab
                  color="secondary"
                  aria-label="add"
                  title="Add process"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faProjectDiagram} className="fa-2x" />
                </Fab>
              </span>
            )}
            {props.addFinalProcess && process.processType !== "FINAL" && (
              <span onClick={props.addFinalProcess}>
                <Fab
                  color="default"
                  aria-label="final"
                  title="Add final process"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faFileInvoice} className="fa-2x" />
                </Fab>
              </span>
            )}
            {props.editProcess && (
              <span onClick={props.editProcess}>
                <Fab
                  color="secondary"
                  aria-label="edit"
                  title="Edit process name"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faEdit} className="fa-2x" />
                </Fab>
              </span>
            )}
            {props.deleteProcess && (
              <span onClick={props.deleteProcess}>
                <Fab
                  color="default"
                  aria-label="delete"
                  title="Delete process"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faTrashAlt} className="fa-2x" />
                </Fab>
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessBox;
