import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import PublicHeader from "./Components/PublicHeader";
import PublicFooter from "./Components/PublicFooter";
import i18n from "../../i18n";

const WhatIs = () => {
  const { auth } = useSelector((state) => {
    return state;
  });

  const bodyStyle = {
    marginLeft: 0,
  };

  return (
    <div className="layout-container">
      <PublicHeader />
      <main className="main-container" style={bodyStyle}>
        <div className="container">
          <div className="row pb-4 mb-4">
            <div className="col-12 text-right">
              {auth.isAuthenticated ? (
                <Link className="btn btn-link red-henkel" to="/home">
                  Dashboard
                </Link>
              ) : (
                <Link className="btn btn-link red-henkel" to="/login">
                  {i18n.t("start.adminLogin")}
                </Link>
              )}
              <Link className="btn btn-link red-henkel" to="/what-is">
                {i18n.t("start.whatIsE2E")}
              </Link>
              <Link className="btn btn-link red-henkel" to="/support-e2e">
                {i18n.t("start.support")}
              </Link>
            </div>
          </div>
        </div>
      </main>
      <PublicFooter />
    </div>
  );
};

export default WhatIs;
