import { combineReducers } from "redux";
import { AuthReducer } from "./authReducer";
import { ProcessesReducer } from "./processesReducer";
import { UsersReducer } from "./usersReducer";
import { ImagesReducer } from "./imagesReducer";
import { LanguagesReducer } from "./languagesReducer";

export default combineReducers({
  auth: AuthReducer,
  processes: ProcessesReducer,
  users: UsersReducer,
  images: ImagesReducer,
  languages: LanguagesReducer,
});
