import React from "react";

import ProcessBox from "../../Processes/Components/ProcessBox";
import i18n from "../../../i18n";

const RootView = (props) => {
  const processes = props.processes;
  return processes ? (
    <div className="row mt-4">
      {processes.map((process) => (
        <div key={process.id} className="col-md-4 col-sm-6 col-12">
          <ProcessBox process={process} link={`/navigation/${process.id}`} />
        </div>
      ))}
    </div>
  ) : (
    <div className="row mt-4">
      <div className="text-center col-12">
        <h4>-- {i18n.t("start.noPublishedProcesses")} --</h4>
      </div>
    </div>
  );
};

export default RootView;
