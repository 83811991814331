import React from "react";

import ProcessBox from "../../Processes/Components/ProcessBox";

const Intermediate = (props) => {
  const current = props.process;
  const children = props.children;
  const parent = props.parent;
  const rootId = props.rootId;
  const language = props.language;

  const currentProcessSize =
    (256 * (children.length === 0 ? 0 : children.length - 1)) / 2;

  const currentProcessMargin = {
    marginTop: `${currentProcessSize}px`,
  };

  return current.processType === "ROOT" ? (
    <div className="row mt-4">
      <div className="col-md-4 col-sm-6 col-12" style={currentProcessMargin}>
        <ProcessBox process={current} link={`/navigation/${current.id}`} />
      </div>
      <div className="col-md-4 col-sm-6 col-12">
        {children.map((process) => {
          return (
            <div key={process.id} className="mb-4 mt-4">
              <ProcessBox
                process={process}
                link={`/navigation/${current.id}/${process.id}`}
                languageCode={language}
              />
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="row mt-4">
      <div className="col-md-4 col-sm-6 col-12" style={currentProcessMargin}>
        <ProcessBox
          process={parent}
          link={`/navigation/${rootId}/${parent.id}`}
          languageCode={language}
        />
      </div>
      <div className="col-md-4 col-sm-6 col-12" style={currentProcessMargin}>
        <ProcessBox
          process={current}
          link={`/navigation/${rootId}/${current.id}`}
          languageCode={language}
        />
      </div>
      <div className="col-md-4 col-sm-6 col-12">
        {children.map((process) => (
          <div key={process.id} className="mb-4">
            <ProcessBox
              process={process}
              link={`/navigation/${rootId}/${process.id}`}
              languageCode={language}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Intermediate;
