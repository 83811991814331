import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import pubsub from "pubsub-js";
import { LinkContainer } from "react-router-bootstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "./Header.scss";
import "./HeaderMenuLinks.scss";
import { signOut } from "../../actions/authActions";

function Header(props) {
  const { firstName, lastName, profile, country } = props.authState;
  let pubsub_token;
  const dispatch = useDispatch();
  const [state, setState] = useState({
    pageTitle: "",
    navIcon: "menu-link-slide",
    dropdownOpen: false,
  });

  useEffect(() => {
    pubsub_token = pubsub.subscribe("setPageTitle", (_, title) => {
      setState({ ...state, pageTitle: title });
    });
    return () => {
      pubsub.unsubscribe(pubsub_token);
    };
  }, [pubsub, state]);

  const toggle = () => {
    setState((prevState) => ({
      ...state,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const logOut = () => {
    dispatch(signOut());
  };

  return (
    <header className="header-container">
      <nav>
        <ul className="d-lg-none">
          <li>
            <a
              id="sidebar-toggler"
              href="#dummylink1"
              className={"menu-link " + state.navIcon}
            >
              <span>
                <em></em>
              </span>
            </a>
          </li>
        </ul>
        <ul className="d-none d-lg-block">
          <li>
            <a
              id="offcanvas-toggler"
              href="#dummylink2"
              className={"menu-link " + state.navIcon}
            >
              <span>
                <em></em>
              </span>
            </a>
          </li>
        </ul>
        <h2 className="header-title">{state.pageTitle}</h2>

        <ul className="float-right">
          <li className="pr-4">Welcome, {firstName}</li>
          <Dropdown
            id="basic-nav-dropdown"
            tag="li"
            isOpen={state.dropdownOpen}
            toggle={toggle}
          >
            <DropdownToggle nav className="has-badge ripple">
              <em className="ion-person"></em>{" "}
              <em
                className={`${
                  state.dropdownOpen
                    ? "ion-android-arrow-dropup"
                    : "ion-android-arrow-dropdown"
                }`}
              ></em>
            </DropdownToggle>
            <DropdownMenu right className="md-dropdown-menu">
              <LinkContainer to="/change-password">
                <DropdownItem>
                  <em className="ion-key icon-fw"></em>
                  Change password
                </DropdownItem>
              </LinkContainer>
              <DropdownItem divider />
              <div onClick={logOut}>
                <DropdownItem>
                  <em className="ion-log-out icon-fw"></em>
                  Logout
                </DropdownItem>
              </div>
            </DropdownMenu>
          </Dropdown>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
