import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

const LinkBox = (props) => {
  const links = props.links;
  const title = props.title ?? "Links of interest";

  return (
    <div className="cardbox">
      <div className="cardbox-heading bg-danger">
        <span className="cardbox-title">{title}</span>
      </div>
      <div className="cardbox-body">
        <div className="cardbox-title">
          {links.map((link) => (
            <div key={link.id} className="row">
              <div className="col-2 pt-2">
                <FontAwesomeIcon icon={faGlobe} size="2x" />
              </div>
              <div className="col-10">
                <h5 className="mv-sm box-title pt-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${link.url}`}
                  >
                    {link.linkName}
                  </a>
                </h5>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LinkBox;
