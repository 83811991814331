import React, { Suspense, lazy, useEffect } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";

/* Auth actions */
import { validateAuth } from "./actions/authActions";

/* Language actions */
import { getAllLanguages } from "./actions/languagesActions";

/* loader component for Suspense */
import PageLoader from "./components/Common/PageLoader";

/* eslint-disable-next-line */
import Core from "./components/Core/Core";
/* eslint-disable-next-line */
import Bootstrap from "./components/Bootstrap/Bootstrap";
/* eslint-disable-next-line */
import Common from "./components/Common/Common";
/* eslint-disable-next-line */
import Colors from "./components/Colors/Colors";
/* eslint-disable-next-line */
import FloatButton from "./components/FloatButton/FloatButton";
/* eslint-disable-next-line */
import Utils from "./components/Utils/Utils";
import Start from "./views/Start/Start";
import PublicSupport from "./views/Start/PublicSupport";
import WhatIs from "./views/Start/WhatIs";

import User from "./views/User/User";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const LazyStart = lazy(() => import("./views/Start/Start"));

const Home = lazy(() => import("./views/Home/Home"));
const Processes = lazy(() => import("./views/Processes/Processes"));
const AddProcess = lazy(() => import("./views/Processes/AddProcess"));
const EditProcess = lazy(() => import("./views/Processes/EditProcess"));
const ProcessImage = lazy(() => import("./views/Processes/ProcessImage"));
const ProcessTree = lazy(() => import("./views/Processes/ProcessTree"));
const FinalProcess = lazy(() => import("./views/Processes/FinalProcess"));
const ProcessPreview = lazy(() => import("./views/Processes/ProcessPreview"));
const PublishProcess = lazy(() => import("./views/Processes/PublishProcess"));

const Users = lazy(() => import("./views/Users/Users"));
const AddUser = lazy(() => import("./views/Users/AddUser"));
const EditUser = lazy(() => import("./views/Users/EditUser"));

const ChangePassword = lazy(() => import("./views/Password/ChangePassword"));

const Media = lazy(() => import("./views/Media/Media"));
const Support = lazy(() => import("./views/Support/Support"));

const Routes = ({ location }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  const animationName = "rag-fadeIn";

  const { isAuthenticated, authVerified, sessionClosed } = useSelector(
    (state) => {
      return state.auth;
    }
  );

  const languagesState = useSelector((state) => {
    return state.languages;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (!authVerified) dispatch(validateAuth());
    if (!languagesState.getDone) dispatch(getAllLanguages());
  }, [authVerified, languagesState, dispatch]);

  if (!authVerified) return <PageLoader />;

  if (!isAuthenticated) {
    return (
      <Switch location={location}>
        <Route path="/start" exact component={Start} />
        <Route
          path="/navigation/:rootId?/:processId?"
          exact
          component={Start}
        />
        <Route path="/login" exact component={User.Login} />
        <Route path="/recover" exact component={User.Recover} />
        <Route path="/activate/:key" exact component={User.Activate} />
        <Route path="/support-e2e" exact component={PublicSupport} />
        <Route path="/what-is" exact component={WhatIs} />
        <Redirect to={`${sessionClosed ? "/login" : "/start"}`} />
      </Switch>
    );
  } else {
    return (
      // Layout component wrapper
      <Core>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                  <Route path="/" exact component={waitFor(Home)} />
                  <Route
                    path="/navigation/:rootId?/:processId?"
                    exact
                    component={waitFor(LazyStart)}
                  />
                  <Route exact path="/home" component={waitFor(Home)} />
                  <Route
                    exact
                    path="/processes"
                    component={waitFor(Processes)}
                  />
                  <Route
                    exact
                    path="/processes/add"
                    component={waitFor(AddProcess)}
                  />
                  <Route
                    exact
                    path="/processes/edit/:id"
                    component={waitFor(EditProcess)}
                  />
                  <Route
                    exact
                    path="/processes/image/:id"
                    component={waitFor(ProcessImage)}
                  />
                  <Route
                    exact
                    path="/processes/tree/:id/:current?"
                    component={waitFor(ProcessTree)}
                  />
                  <Route
                    extact
                    path="/processes/final/:rootId/:id/:languageCode?"
                    component={waitFor(FinalProcess)}
                  />
                  <Route
                    extact
                    path="/processes/preview/:id/:current/:languageCode?"
                    component={waitFor(ProcessPreview)}
                  />
                  <Route
                    extact
                    path="/processes/publish/:id"
                    component={waitFor(PublishProcess)}
                  />
                  <Route
                    exact
                    path="/change-password"
                    component={waitFor(ChangePassword)}
                  />
                  <Route exact path="/media" component={waitFor(Media)} />
                  <Route exact path="/users" component={waitFor(Users)} />
                  <Route exact path="/users/add" component={waitFor(AddUser)} />
                  <Route
                    exact
                    path="/users/edit/:id"
                    component={waitFor(EditUser)}
                  />
                  <Route exact path="/support" component={waitFor(Support)} />

                  <Redirect to="/home" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Core>
    );
  }
};

export default withRouter(Routes);
