import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FormValidator from "../Forms/Validator.js";
import Footer from "../../components/Footer/Footer";
import "../Forms/Material.scss";
import LogoE2e from "../../images/logo_e2e.png";
import HenkelLogo from "../../images/logo_henkel.png";

import {
  validateActivationKey,
  putPasswordReset,
} from "../../actions/usersActions";

const Activate = (props) => {
  const dispatch = useDispatch();
  const key = props.match.params.key;

  const usersState = useSelector((state) => {
    return state.users;
  });

  const [formActivate, setFormActivate] = useState({
    password: "",
    repeatPassword: "",
    submitted: false,
    success: false,
    errors: {},
  });

  useEffect(() => {
    dispatch(validateActivationKey(key));
  }, [dispatch, key]);

  const validateOnChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    setFormActivate({
      ...formActivate,
      [input.name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    const inputs = [...form.elements].filter((i) =>
      ["INPUT", "SELECT"].includes(i.nodeName)
    );

    const { errors, hasError } = FormValidator.bulkValidate(inputs);

    setFormActivate({
      ...formActivate,
      submitted: !hasError,
      errors,
    });

    if (hasError) {
      toast(`Form data is not correct. Check your information.`, {
        type: "warning",
        position: "top-right",
      });
      return;
    }

    dispatch(
      putPasswordReset(key, formActivate.password, formActivate.repeatPassword)
    );
  };

  const hasError = (inputName, method) => {
    return (
      formActivate.errors &&
      formActivate.errors[inputName] &&
      formActivate.errors[inputName][method]
    );
  };

  if (usersState.passwordResetDone) {
    if (usersState.passwordResetError) {
      toast(usersState.passwordResetError, {
        type: "warning",
        position: "top-right",
      });
    } else {
      toast(
        "Your password has been saved successfully. You will be redirected to login in a few minutes.",
        {
          type: "success",
          position: "top-right",
        }
      );

      return <Redirect to="/login" />;
    }
  }

  return (
    <div className="layout-container bg-gray-henkel">
      <div className="page-container">
        <div className="container-full">
          <nav className="navbar bg-light fixed-top">
            <img src={HenkelLogo} alt="Henkel" className="logo-signin" />
          </nav>
          <div className="mt-4 mb-4 pt-4 pb-4">&nbsp;</div>
          <div className="container container-xs bg-common rounded-lg mt-4">
            <div className="row mb-4">
              <div className="text-center col-sm-6 offset-sm-3">
                <img className="mv-lg img-fluid" src={LogoE2e} alt="Henkel" />
              </div>
            </div>
            {!usersState.activationValidated && !formActivate.submitted ? (
              <div className="cardbox ng-fadeIn shadow" id="confirmation">
                <div className="cardbox-heading">
                  <em className="ion-checkmark-round float-right icon-lg" />
                  <h4 className="mt0">Validating...</h4>
                </div>
                <div className="cardbox-body">
                  <p>Please wait, we are validating your information.</p>
                </div>
              </div>
            ) : usersState.activationKeyError === "" ? (
              <div className=" pr-4 pl-4">
                <form
                  className="cardbox form-validate shadow"
                  action=""
                  name="formRecover"
                  onSubmit={onSubmit}
                >
                  <div className="cardbox-heading">
                    <p className="text-center text-inherit">
                      Fill the following fields to assign your new password.
                    </p>
                  </div>
                  <div className="cardbox-body">
                    <div className="mda-form-group float-label mda-input-group">
                      <div className="mda-form-control">
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          invalid={
                            hasError("password", "required") ||
                            hasError("password", "regexp")
                          }
                          onChange={validateOnChange}
                          data-validate='["required", "regexp"]'
                          data-param="^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d]))).{12,}$"
                          value={formActivate.password}
                          placeholder="Enter your new password. Must be 12 characters"
                        />
                        <div className="mda-form-control-line" />
                      </div>
                      <span className="mda-input-group-addon">
                        <em className="ion-key icon-lg" />
                      </span>
                    </div>
                    {hasError("password", "required") && (
                      <div className="alert alert-warning">
                        Field is required
                      </div>
                    )}
                    {hasError("password", "regexp") && (
                      <div className="alert alert-warning">
                        Field must be 12 characters and including 1 uppercase, 1
                        lowercase and 1 number
                      </div>
                    )}
                    <div className="mda-form-group float-label mda-input-group">
                      <div className="mda-form-control">
                        <Input
                          type="password"
                          name="repeatPassword"
                          invalid={hasError("repeatPassword", "equalto")}
                          onChange={validateOnChange}
                          data-validate='["equalto"]'
                          data-param="password"
                          value={formActivate.repeatPassword}
                          placeholder="Repeat your new password"
                        />
                        <div className="mda-form-control-line" />
                      </div>
                      <span className="mda-input-group-addon">
                        <em className="ion-key icon-lg" />
                      </span>
                    </div>
                    {hasError("repeatPassword", "equalto") && (
                      <div className="alert alert-warning">
                        Field must be equal to Password
                      </div>
                    )}
                    <button
                      className="btn btn-henkel-primary btn-block"
                      type="submit"
                      disabled={usersState.loading}
                    >
                      Save new password
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div className="cardbox">
                <div className="cardbox-body">
                  <p className="text-center text-inherit">
                    {usersState.activationKeyError}
                  </p>
                  <Link
                    className="btn btn-henkel-secondary btn-block"
                    to="/recover"
                  >
                    Request new password
                  </Link>
                </div>
              </div>
            )}
          </div>
          <Footer fromLogin={true} />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Activate;
