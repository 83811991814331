import {
  GET_ALL_LANGUAGES,
  SET_LANGUAGE,
  LANGUAGES_LOADED,
  ERROR,
} from "../types";
import Api from "../components/Api/Api";

export function getAllLanguages() {
  return async (dispatch) => {
    const result = await Api.getLanguages();
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getAllLanguagesSuccess(result.languages));
  };
}

export function setLanguage(languageCode) {
  return async (dispatch) => {
    dispatch(setLanguageDone(languageCode));
  };
}

const getAllLanguagesSuccess = (languages) => ({
  type: GET_ALL_LANGUAGES,
  payload: languages,
});

const setLanguageDone = (languageCode) => ({
  type: SET_LANGUAGE,
  payload: languageCode,
});

const error = (error) => ({ type: ERROR, payload: error });
