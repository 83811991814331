import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const ResponsibleBox = (props) => {
  const {
    responsibleName,
    responsibleEmail,
    responsiblePhone,
  } = props.responsible;
  const title = props.title ?? "Responsible";

  return (
    <div className="cardbox">
      <div className="cardbox-heading bg-danger">
        <span className="cardbox-title">{title}</span>
      </div>
      <div className="cardbox-body">
        <div className="cardbox-title">
          <div className="row">
            <div className="col-2 pt-2">
              <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </div>
            <div className="col-10">
              <h5 className="mv-sm box-title">{responsibleName}</h5>
              <p className="m0 box-info">
                <a href={`mailto:${responsibleEmail}`}>{responsibleEmail}</a>
              </p>
              {responsiblePhone !== "" && (
                <p className="mt-1 box-info">
                  <a href={`tel:${responsiblePhone}`}>{responsiblePhone}</a>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBox;
