import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

import i18n from "../../i18n";

const SupportBox = (props) => {
  const small = {
    marginLeft: 0,
  };
  const full = props.full ?? true;

  return (
    <>
      <h4 className="mt-1">{i18n.t("start.support")}</h4>
      <div className="row">
        <div className="col-md-6">
          <div className="cardbox">
            <div className="cardbox-heading">
              <h3 className="mt-1 mb-1">Ariba</h3>
            </div>
            <div className="mda-list mb-4">
              <div className="mda-list-item">
                <div className="row">
                  <div className="col-2">
                    <FontAwesomeIcon
                      className="mt-1 ml-3"
                      icon={faUserCircle}
                      size="3x"
                    />
                  </div>
                  <div className="col-10 pl-4">
                    <h4 className="mt-1 mb-2">Jose-Rafael Gomez</h4>
                    <p>
                      <a href="mailto:jose-rafael.gomez@henkel.com">
                        jose-rafael.gomez@henkel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cardbox">
            <div className="cardbox-heading">
              <h3 className="mt-1 mb-1">Raydoo</h3>
            </div>
            <div className="mda-list mb-4">
              <div className="mda-list-item">
                <div className="row">
                  <div className="col-2">
                    <FontAwesomeIcon
                      className="mt-1 ml-3"
                      icon={faUserCircle}
                      size="3x"
                    />
                  </div>
                  <div className="col-10 pl-4">
                    <h4 className="mt-1 mb-2">Ana Sánchez</h4>
                    <p>
                      <a href="mailto:ana.sanchez@henkel.com ">
                        ana.sanchez@henkel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="cardbox">
            <div className="cardbox-heading">
              <h3 className="mt-1 mb-1">Snow </h3>
            </div>
            <div className="mda-list mb-4">
              <div className="mda-list-item">
                <div className="row">
                  <div className="col-2">
                    <FontAwesomeIcon
                      className="mt-1 ml-3"
                      icon={faUserCircle}
                      size="3x"
                    />
                  </div>
                  <div className="col-10 pl-4">
                    <h4 className="mt-1 mb-2">Rodrigo Vazquez</h4>
                    <p>
                      <a href="mailto:rodrigo.vazquez@henkel.com">
                        rodrigo.vazquez@henkel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mda-list mb-4">
              <div className="mda-list-item">
                <div className="row">
                  <div className="col-2">
                    <FontAwesomeIcon
                      className="mt-1 ml-3"
                      icon={faUserCircle}
                      size="3x"
                    />
                  </div>
                  <div className="col-10 pl-4">
                    <h4 className="mt-1 mb-2">Erik Peñuñuri</h4>
                    <p>
                      <a href="mailto:erik.penunuri@henkel.com">
                        erik.penunuri@henkel.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-md-8">
          <div className="cardbox">
            <div className="cardbox-heading">
              <h4>Tech documentation</h4>
            </div>
            <div className="cardbox-body">
              <table className="table table-striped table-hover table-responsive">
                <tbody>
                  <tr>
                    <td width="5%">
                      <FontAwesomeIcon
                        className="mda-list-item-img text-danger"
                        icon={faFilePdf}
                        size="3x"
                      />
                    </td>
                    <td>
                      <h4>
                        User Guide
                        <br />
                        <small style={small}>
                          Last update: <strong>August 2, 2020</strong>
                        </small>
                      </h4>
                    </td>
                  </tr>
                  <tr>
                    <td width="5%">
                      <FontAwesomeIcon
                        className="mda-list-item-img text-danger"
                        icon={faFilePdf}
                        size="3x"
                      />
                    </td>
                    <td>
                      <h4>
                        Access control
                        <br />
                        <small style={small}>
                          Last update: <strong>August 3, 2020</strong>
                        </small>
                      </h4>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default SupportBox;
