import Api from "../components/Api/Api";
import {
  LOADING,
  ERROR,
  GET_PROCESS_LIST,
  GET_ROOT_PROCESSES,
  GET_REPORT,
  POST_PROCESS,
  PUT_PROCESS,
  GET_PROCESS,
  DELETE_PROCESS,
  SET_PROCESS_ALIAS,
  POST_CONTACT,
  DELETE_CONTACT,
  POST_LINK,
  DELETE_LINK,
  UPDATE_DESCRIPTION,
  POST_VIDEO,
  DATA_LOADED,
  CLEAN_PROCESS,
  POST_DOCUMENT,
  POST_RATING,
  POST_VISIT,
} from "../types/index";

export function getReport(initialDate, finalDate) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.getReport(initialDate, finalDate);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getReportSuccess(result.report));
  };
}

export function getAllProcesses() {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.getProcesses();
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getAllProcessesSuccess(result.processesList));
  };
}

export function getRootProcesses() {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.getRootProcesses();
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getRootProcessesSuccess(result.processesList));
  };
}

export function getRootPublishedProcesses() {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.getRootProcesses();
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    const processesList = result.processesList.filter(
      (process) => process.status === "Published"
    );

    dispatch(getRootProcessesSuccess(processesList));
  };
}

export function getProcess(id) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.getProcess(id);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getProcessSuccess(result.process));
  };
}

export function getProcessWithLanguage(id, languageCode) {
  return async (dispatch) => {
    dispatch(loading());
    const result = await Api.getProcessLanguage(id, languageCode);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(getProcessWithLanguageSuccess(result.process));
  };
}

export function newProcess(process) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.registerProcess(process);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(newProcessSuccess(result.process));
  };
}

export function editProcess(id, process) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.updateProcess(id, process);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(editProcessSuccess(result.process));
  };
}

export function dropProcess(id) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.deleteProcess(id);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(dropProcessSuccess(result));
  };
}

export function setProcessAlias(processId, languageCode, processAlias) {
  return async (dispatch) => {
    const result = await Api.setProcessAlias(
      processId,
      languageCode,
      processAlias
    );

    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(setProcessAliasSuccess(result.processAlias));
  };
}

export function newContact(processId, contact) {
  return async (dispatch) => {
    const result = await Api.registerContact(processId, contact);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(setContactSuccess(result.contact));
  };
}

export function dropContact(id) {
  return async (dispatch) => {
    const result = await Api.deleteContact(id);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(dropContactSuccess(result));
  };
}

export function newLink(processId, link) {
  return async (dispatch) => {
    const result = await Api.registerLink(processId, link);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(setLinkSuccess(result.contact));
  };
}

export function dropLink(id) {
  return async (dispatch) => {
    const result = await Api.deleteLink(id);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(dropLinkSuccess(result));
  };
}

export function editDescription(processId, languageCode, description) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.setDescription(
      processId,
      languageCode,
      description
    );
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(editDescriptionSuccess(result.description));
  };
}

export function newVideo(processId, languageCode, video) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.setVideo(processId, languageCode, video);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(newVideoSuccess(result.video));
  };
}

export function newDocument(processId, languageCode, video) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.setDocument(processId, languageCode, video);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(newDocumentSuccess(result.document));
  };
}

export function setVisit(rootId, processId) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.setVisit(rootId, processId);
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(setVisitSuccess(result.visit));
  };
}

export function updateRating(rootId, processId, stars, email, comments) {
  return async (dispatch) => {
    dispatch(loading());

    const result = await Api.setRating(
      rootId,
      processId,
      stars,
      email,
      comments
    );
    if (!result.success) {
      dispatch(error(result.error));
      return;
    }

    dispatch(updateRatingSuccess(result.rating));
  };
}

export function clean() {
  return {
    type: CLEAN_PROCESS,
  };
}

export function dataLoaded() {
  return {
    type: DATA_LOADED,
  };
}

const loading = () => ({
  type: LOADING,
});

const getReportSuccess = (result) => ({
  type: GET_REPORT,
  payload: result,
});

const getAllProcessesSuccess = (result) => ({
  type: GET_PROCESS_LIST,
  payload: result,
});

const getRootProcessesSuccess = (result) => ({
  type: GET_ROOT_PROCESSES,
  payload: result,
});

const getProcessSuccess = (result) => ({
  type: GET_PROCESS,
  payload: result,
});

const getProcessWithLanguageSuccess = (result) => ({
  type: GET_PROCESS,
  payload: result,
});

const newProcessSuccess = (result) => ({
  type: POST_PROCESS,
  payload: result,
});

const editProcessSuccess = (result) => ({
  type: PUT_PROCESS,
  payload: result,
});

const dropProcessSuccess = (result) => ({
  type: DELETE_PROCESS,
  payload: result,
});

const setProcessAliasSuccess = (result) => ({
  type: SET_PROCESS_ALIAS,
  payload: result,
});

const setContactSuccess = (result) => ({
  type: POST_CONTACT,
  payload: result,
});

const dropContactSuccess = (result) => ({
  type: DELETE_CONTACT,
  payload: result,
});

const setLinkSuccess = (result) => ({
  type: POST_LINK,
  payload: result,
});

const dropLinkSuccess = (result) => ({
  type: DELETE_LINK,
  payload: result,
});

const editDescriptionSuccess = (result) => ({
  type: UPDATE_DESCRIPTION,
  payload: result,
});

const newVideoSuccess = (result) => ({
  type: POST_VIDEO,
  payload: result,
});

const newDocumentSuccess = (result) => ({
  type: POST_DOCUMENT,
  payload: result,
});

const setVisitSuccess = (result) => ({
  type: POST_VISIT,
  payload: result,
});

const updateRatingSuccess = (result) => ({
  type: POST_RATING,
  payload: result,
});

const error = (error) => ({ type: ERROR, payload: error });
